import {IWixAPI} from '@wix/yoshi-flow-editor'
import {
  EVENTS_APP_ID,
  EVENTS_MEMBERS_WIDGET_ID,
  MEMBERS_APP_DEF_ID,
  MEMBERS_INFO_APP_ID,
  MEMBERS_INFO_SECTION_ID,
  MEMBERS_PAGE_SECTION_ID,
  MEMBERS_AREA_V2_APP_ID,
  MEMBERS_AREA_V2_SECTION_ID,
} from '@wix/wix-events-commons-statics'

export const getMembersAPI = (wixCodeApi: IWixAPI) => wixCodeApi.site.getPublicAPI(MEMBERS_APP_DEF_ID)

export const isMembersInstalled = async (wixCodeApi: IWixAPI) =>
  (
    await Promise.all([
      wixCodeApi.site.isAppSectionInstalled({
        sectionId: MEMBERS_INFO_SECTION_ID,
        appDefinitionId: MEMBERS_INFO_APP_ID,
      }),
      wixCodeApi.site.isAppSectionInstalled({
        sectionId: MEMBERS_AREA_V2_SECTION_ID,
        appDefinitionId: MEMBERS_AREA_V2_APP_ID,
      }),
    ])
  ).find(Boolean)

const isMembersEventsPageInstalled = (wixCodeApi: IWixAPI) =>
  wixCodeApi.site.isAppSectionInstalled({
    appDefinitionId: EVENTS_APP_ID,
    sectionId: MEMBERS_PAGE_SECTION_ID,
  })

const isMembersEventsSlotInstalled = async (wixCodeApi: IWixAPI) => {
  try {
    const api = await getMembersAPI(wixCodeApi)
    return api.isAppSectionInstalled({widgetId: EVENTS_MEMBERS_WIDGET_ID})
  } catch (e) {
    // getSectionUrl always throws if widget is not installed (members should fix it)
    // console.error(e)
  }
  return false
}

export const isEventsInMembersInstalled = async (wixCodeApi: IWixAPI): Promise<boolean> =>
  Boolean(
    (await Promise.all([isMembersEventsPageInstalled(wixCodeApi), isMembersEventsSlotInstalled(wixCodeApi)])).find(
      Boolean,
    ),
  )

export const getCurrentMemberId = (wixCodeApi: IWixAPI) => {
  const currentUser = wixCodeApi.user.currentUser
  if (currentUser.loggedIn) {
    return currentUser.id
  }
  return null
}
